<template lang="pug">
	bot-setup
</template>

<script>
import BotSetup from '@/components/Tables/BotSetup';

export default {
    name: 'BotSettings',
    components: {
        BotSetup,
    },
};
</script>
