<template lang="pug">
	.change-bot-avatar
		.d-flex.align-items-center.justify-content-between.change-bot-avatar__steps(v-if="totalSteps == 2")
			svg(width='51' height='3' viewbox='0 0 51 3' fill='none' xmlns='http://www.w3.org/2000/svg')
				line(y1='1.5' x2='51' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='6.5 13 13 13 6.5')

			template(v-for="i in totalSteps")
				.create__step.create-bot__step(:class="{'active pulsing': step == i, 'done': step > i}")
					span(v-if="step <= i") {{ i }}
					img(v-if="step > i" src="/assets/img/icons/done-green.svg")
				svg(width='51' height='3' viewbox='0 0 51 3' fill='none' xmlns='http://www.w3.org/2000/svg')
					line(y1='1.5' x2='51' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='6.5 13 13 13 6.5')

		.d-flex.align-items-center.justify-content-between.change-bot-avatar__steps(v-if="totalSteps == 3")
			template(v-for="i in 3")
				.create__step.create-bot__step(:class="{'active pulsing': step == i, 'done': step > i}")
					span(v-if="step <= i") {{ i }}
					img(v-if="step > i" src="/assets/img/icons/done-green.svg")
				svg(v-if="i != 3" width='51' height='3' viewbox='0 0 51 3' fill='none' xmlns='http://www.w3.org/2000/svg')
					line(y1='1.5' x2='51' y2='1.5' :stroke="isDarkColor ? '#444444' : '#E4E7EA'" stroke-width='2' stroke-dasharray='6.5 13 13 13 6.5')

		div(v-if="step == 1")
			h3.text-bold.change-bot-avatar__subtitle {{ $t('bots.changeBotAvatar.step1.title') }}
			
			template(v-if="!isMobile")
				p.b1.text-grey.change-bot-avatar__txt {{ isWindows ? $t('bots.changeBotAvatar.step1.descWindows') : $t('bots.changeBotAvatar.step1.desc')}}
				.border-rounded.change-bot-avatar__image(:style="{background: `url('/assets/img/settingsBot/first_step_${isDarkColor ? 'dark' : 'light'}${isWindows ? '-windows' : ''}.webp') ${isDarkColor ? 'bottom' : 'center'} left / 95.81% no-repeat, var(--primary-bg-color)`}")
			
			template(v-if="isMobile")
				p.b1.text-grey.change-bot-avatar__txt {{ !isiPhone ? $t('bots.changeBotAvatar.step1.descAndroid') : $t('bots.changeBotAvatar.step1.desc')}}
				img.w-100.mb-5(:src="`/assets/img/settingsBot/first_step_${isDarkColor ? 'dark' : 'light'}_mobile${!isiPhone ? '-android' : ''}.webp`" alt="Screenshot from Telegram")
			
			.btn.btn-primary.change-bot-avatar__btn(@click="step++") {{ $t('wizard.next') }}

		div(v-if="step == 2")
			template(v-if="!isMobile")
				h3.text-bold.change-bot-avatar__subtitle {{ $t('bots.changeBotAvatar.step2.title') }}
				p.b1.text-grey.change-bot-avatar__txt {{ $t('bots.changeBotAvatar.step2.desc') }}
				.border-rounded.change-bot-avatar__image(:style="{background: `url('/assets/img/settingsBot/second_step_${isDarkColor ? 'dark' : 'light'}${isWindows ? '-windows' : ''}.webp') ${isDarkColor ? 'bottom' : 'center'} left / 95.81% no-repeat, var(--primary-bg-color)`}")
			template(v-if="isMobile")
				h3.text-bold.change-bot-avatar__subtitle {{ !isiPhone ? $t('bots.changeBotAvatar.step2.titleAndroid') : $t('bots.changeBotAvatar.step2.title') }}
				p.b1.text-grey.change-bot-avatar__txt {{ !isiPhone ? $t('bots.changeBotAvatar.step2.descAndroid') : $t('bots.changeBotAvatar.step2.desc') }}
				img.w-100.mb-5(:src="`/assets/img/settingsBot/second_step_${isDarkColor ? 'dark' : 'light'}_mobile${!isiPhone ? '-android' : ''}.webp`" alt="Screenshot from Telegram")
			
			template(v-if="isMobile && !isiPhone")
				.d-flex.justify-content-between
					.btn.change-bot-avatar__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
					.btn.btn-primary.change-bot-avatar__btn.create__btn--half-width(@click="step++") {{ $t('wizard.next') }}

			template(v-if="!isMobile || isiPhone")
				.d-flex.justify-content-between
					.btn.change-bot-avatar__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
					.btn.btn-primary.change-bot-avatar__btn.create__btn--half-width(@click="closeModal") {{ $t('forms.done') }}

		div(v-if="step == 3")
			h3.text-bold.change-bot-avatar__subtitle {{ $t('bots.changeBotAvatar.step3.title') }}
			p.b1.text-grey.change-bot-avatar__txt {{ $t('bots.changeBotAvatar.step3.desc') }}
			img.w-100.mb-5(:src="`/assets/img/settingsBot/third_step_${isDarkColor ? 'dark' : 'light'}_mobile-android.webp`" alt="Screenshot from Telegram")

			.d-flex.justify-content-between
				.btn.change-bot-avatar__btn.create__btn--half-width(@click="step--") {{ $t('wizard.prev') }}
				.btn.btn-primary.change-bot-avatar__btn.create__btn--half-width(@click="closeModal") {{ $t('forms.done') }}

</template>

<script>
import { DEVICE } from '@/utils/device';

export default {
    name: 'ChangeBotAvatar',
    data: () => ({
        step: null,
        totalSteps: null,
        isWindows: false,
        isiPhone: false,
    }),
    mounted() {
        this.isWindows = DEVICE.isWindows();
        this.isiPhone = DEVICE.isiPhone();
        this.step = this.$route.params.step || 1;
        this.totalSteps = this.isMobile && !this.isiPhone ? 3 : 2;
    },
    async created() {
        if (this.$route.query && this.$route.query.id) {
            this.step = this.isMobile && !this.isiPhone ? 3 : 2;
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.change-bot-avatar {
    @include respond-below(sm-new) {
        max-width: 340px;
        margin: 0 auto 53px auto;
    }

    &__steps {
        @include respond-below(sm-new) {
            padding: 20px 0;
        }
    }

    &__subtitle {
        margin-top: 25px !important;
        margin-bottom: 15px !important;

        @include respond-below(sm-new) {
            margin-top: 0 !important;
        }
    }

    &__txt {
        margin-bottom: 25px;

        @include respond-below(sm-new) {
            margin-bottom: 20px;
        }
    }

    &__image {
        background-color: var(--primary-bg-color);
        border: 1px solid var(--grayscale-dark-line);
        min-height: 316px;
        margin-bottom: 25px;
    }

    &__btn {
        height: 50px;
    }
}
</style>
