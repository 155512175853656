<template lang="pug">
	.w-100.bot-setup(v-if="bot")
		.d-flex.justify-content-start.align-items-center.bot-setup__header
			svg.cursor-pointer.mr-10.bot-setup__back(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' @click="$router.push({name: 'project_bot', params: {id: lastOpenedProjectId}})")
				path(d='M14.9998 19.9201L8.47984 13.4001C7.70984 12.6301 7.70984 11.3701 8.47984 10.6001L14.9998 4.08008' stroke='#8292A1' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round')
			h2.h2.bot-setup__title {{ $t('bots.settings.title') }}
			a.bot-setup__title-question(href='https://docs.paywall.pw/rus/neirobot/kak-nastroit' target='_blank')
				svg.cursor-pointer(width='28' height='28' viewbox='0 0 28 28' fill='none' xmlns='http://www.w3.org/2000/svg')
					circle(cx='14' cy='14' r='14' :fill="isDarkColor ? '#27282A' : '#FFFFFF'")
					path(d='M13.58 15.5867C13.58 15.3502 13.5924 15.1324 13.6173 14.9333C13.6547 14.7218 13.7231 14.5164 13.8227 14.3173C13.9347 14.1058 14.084 13.888 14.2707 13.664C14.4698 13.44 14.7311 13.1911 15.0547 12.9173C15.5773 12.4693 15.9756 12.0649 16.2493 11.704C16.5231 11.3431 16.66 10.9573 16.66 10.5467C16.66 9.94933 16.4609 9.45778 16.0627 9.072C15.6644 8.68622 15.1169 8.49333 14.42 8.49333C13.7231 8.49333 13.1756 8.68622 12.7773 9.072C12.3791 9.45778 12.18 9.94933 12.18 10.5467H10.5C10.5 10.0364 10.5871 9.56978 10.7613 9.14667C10.948 8.71111 11.2093 8.33778 11.5453 8.02667C11.8938 7.70311 12.3107 7.45422 12.796 7.28C13.2813 7.09333 13.8227 7 14.42 7C15.0173 7 15.5587 7.09333 16.044 7.28C16.5293 7.45422 16.94 7.70311 17.276 8.02667C17.6244 8.33778 17.8858 8.71111 18.06 9.14667C18.2467 9.56978 18.34 10.0364 18.34 10.5467C18.34 10.8951 18.284 11.2249 18.172 11.536C18.06 11.8471 17.9044 12.1458 17.7053 12.432C17.5187 12.7058 17.3009 12.9733 17.052 13.2347C16.8031 13.4836 16.548 13.72 16.2867 13.944C16.0751 14.1182 15.9009 14.2862 15.764 14.448C15.6271 14.5973 15.5151 14.7467 15.428 14.896C15.3533 15.0329 15.2973 15.176 15.26 15.3253C15.2351 15.4622 15.2227 15.6116 15.2227 15.7733V16.1467H13.58V15.5867ZM14.42 20.44C14.0591 20.44 13.748 20.3156 13.4867 20.0667C13.2378 19.8053 13.1133 19.4942 13.1133 19.1333C13.1133 18.76 13.2378 18.4489 13.4867 18.2C13.748 17.9511 14.0591 17.8267 14.42 17.8267C14.7809 17.8267 15.0858 17.9511 15.3347 18.2C15.596 18.4489 15.7267 18.76 15.7267 19.1333C15.7267 19.4942 15.596 19.8053 15.3347 20.0667C15.0858 20.3156 14.7809 20.44 14.42 20.44Z' fill='#52A7F9')

		.basic-box-shadow.border-rounded.w-100.foreground-color.mb-4.connected-bot
			h2.h3.text-bold {{ $t('bots.settings.basic') }}
			.d-flex.align-items-center.flex-wrap.bot-setup__basic
				img.connected-bot__logo.bot-setup__logo(
					v-if="bot.image && bot.image.contentUrl"
					:src="bot.image.contentUrl"
					:alt="`${$t('project.logo')} ${bot.firstName}`"
					width="145"
					height="145"
				)
				.d-flex.justify-content-center.align-items-center.flex-shrink-0.bot-setup__logo(v-else)
					span {{ `${bot.firstName.replace(/[^a-zA-Zа-яА-Я0-9]/g, '').slice(0, 1)}` }}

				.connected-bot__right
					span.d-block.vuetable__status.connected-bot__status.bot-setup__inner-status(v-if="bot.isActive()") {{ $t('bots.status.connected') }}
					.d-flex.align-items-center.bot-setup__inner-status(v-if="!bot.isActive()")
						span.d-block.vuetable__status.mb-0.text-error.connected-bot__status {{ $t('bots.status.noContact') }}
						.position-relative.ml-10
							span.d-flex.justify-content-center.align-items-center.cursor-pointer.rounded-circle.connect-resource__question(
								:id="!isMobile ? `connectResourceTooltip` : ''"
								@click="isMobile ? $bvModal.show(`connectResourceTooltip`) : ''"
							) ?

						modal-tooltip(
							:id="`connectResourceTooltip`"
							:title="$t('resources.status.noContact')"
							:text="`${$t('bots.checkAvailability2')}&nbsp;<a href='https://docs.paywall.pw/rus/neirobot/net-svyazi-s-botom' target='_blank'>${$t('bots.instruction')}</a>.`"
							:textMobile="`${$t('bots.checkAvailability2')}&nbsp;<a href='https://docs.paywall.pw/rus/neirobot/net-svyazi-s-botom' target='_blank'>${$t('bots.instruction')}</a>.`"
							:classTooltip="'small-tooltip'"
						)

					.d-flex.align-items-center.flex-wrap.bot-setup__info
						span.b1.w-100.d-none.d-sm-flex.mb-10.bot-setup__name(v-if="bot.firstName") {{ bot.firstName }}
						span.b1.text-grey.mb-10.bot-setup__username(v-if="bot.username") @{{ bot.username }}
						pw-icon-copy.mr-10.mb-10.bot-setup__copy(:text="`@${bot.username}`", :textSuccess="`😊 &nbsp;&nbsp;&nbsp;${this.$t('bot.usernameCopied')}`")
						a.mr-10.mb-10.connected-bot__link(:href="`https://t.me/${bot.username}`" target='_blank')
							i.fontello-icon-link
						a.mb-10.connected-bot__link(href="javascript:void(0)" @click="refresh")
							i.fontello-icon-refresh(:class="{'refresh': isLoading}")

					.d-flex
						.btn.mr-10.connected-bot__btn.connected-bot__btn-avatar(@click="$bvModal.show('change-bot-avatar'), editField()" :class="{'is-dark': isDarkColor}")
							span.b2 {{ $t('bots.changeAvatar') }}

						a.text-decoration-none(:href="`https://t.me/${bot.username}`" target='_blank')
							span.b2.btn.connected-bot__btn.connected-bot__btn-chat(:class="{'is-dark': isDarkColor}") {{ $t('bots.startChat') }}

				.w-100.bot-setup__start-chat
					a.text-decoration-none(:href="`https://t.me/${bot.username}`" target='_blank')
						span.b2.btn.connected-bot__btn.mb-10.connected-bot__btn-start-chat(:class="{'is-dark': isDarkColor}") {{ $t('bots.startChat') }}

			.bot-setup__inner
				span.mb-10.text-grey {{ $t('bots.settings.botName') }}
				.d-flex.justify-content-between.align-items-center.position-relative.bot-setup__input
					span.b1.bot-setup__txt.bot-setup__about {{ bot.firstName }}
					i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.d-none.d-sm-block(@click="$bvModal.show('change-bot-name'), editField()")
					svg.ml-auto.d-block.d-sm-none.flex-shrink-0.bot-setup__dots(@click="onOpenPopover" :id="'bot-name'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
						g(clip-path="url(#a)")
							path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
								defs
									clipPath(id="a")
										path(fill="#fff" d="M0 0h18v18H0z")
					b-popover(v-if="isMobile" ref="popoverMenuDotsName" :target="'bot-name'" placement="left" :custom-class="'popover-settings'" trigger="click")
						a.fontello-btn.fontello-btn-primary
							i.text-primary.fontello.fontello-icon-edit.cursor-pointer(@click="$bvModal.show('change-bot-name'), editField()")

			.bot-setup__inner
				span.mb-10.text-grey {{ $t('bots.settings.aboutBot') }}
				.d-flex.justify-content-between.align-items-center.position-relative.bot-setup__input
					p.b1.bot-setup__txt.bot-setup__about {{ bot.shortDescription ? bot.shortDescription : '' }}
					i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.d-none.d-sm-block(@click="$bvModal.show('change-bot-info'), editField()")
					svg.ml-auto.d-block.d-sm-none.flex-shrink-0.bot-setup__dots(@click="onOpenPopover" :id="'bot-about'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
						g(clip-path="url(#a)")
							path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
								defs
									clipPath(id="a")
										path(fill="#fff" d="M0 0h18v18H0z")
					b-popover(v-if="isMobile" ref="popoverMenuDotsAbout" :target="'bot-about'" placement="left" :custom-class="'popover-settings'" trigger="click")
						a.fontello-btn.fontello-btn-primary
							i.text-primary.fontello.fontello-icon-edit.cursor-pointer(@click="$bvModal.show('change-bot-info'), editField()")

			div
				span.mb-10.text-grey {{ $t('bots.settings.description') }}
				.d-flex.justify-content-between.align-items-center.position-relative.bot-setup__textarea
					p.b1.bot-setup__txt.bot-setup__desc {{ bot.description ? bot.description : '' }}
					i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.d-none.d-sm-block(@click="$bvModal.show('change-bot-desc'), editField()")
					svg.ml-auto.d-block.d-sm-none.flex-shrink-0.bot-setup__dots(@click="onOpenPopover" :id="'bot-desc'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
						g(clip-path="url(#a)")
							path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
								defs
									clipPath(id="a")
										path(fill="#fff" d="M0 0h18v18H0z")
					b-popover(v-if="isMobile" ref="popoverMenuDotsDesc" :target="'bot-desc'" placement="left" :custom-class="'popover-settings'" trigger="click")
						a.fontello-btn.fontello-btn-primary
							i.text-primary.fontello.fontello-icon-edit.cursor-pointer(@click="$bvModal.show('change-bot-desc'), editField()")

		attraction-link.connected-bot(
			v-if="item.hasPaymentSystem() && item.isActive()"
			:login="`@${bot.username}`"
			:title="$t('project.attractionLink')"
			:classTitle="'h3 text-bold'"
			:telegramPage="true"
			:botUsername="true"
			:paywallPage="true"
			:paywallLink="paymentLinkDefault"
			:botImage="bot.image && bot.image.contentUrl ? bot.image.contentUrl : ''"
			:botName="bot.firstName"
		)

		.basic-box-shadow.border-rounded.w-100.foreground-color.connected-bot
			h2.h3.text-bold.bot-setup__subtitle-token {{ $t('bots.settings.APIToken') }}

			.d-flex.justify-content-between.align-items-center.mt-10.position-relative.bot-setup__input
				span.b3.bot-setup__txt.bot-setup__token
					span {{ tokenStart }}
					span.text-grey ••••••••••••••••••••••••••••••••••••••••
					span {{ tokenEnd }}
				i.text-primary.fontello.fontello-icon-edit.cursor-pointer.btn-icon.d-none.d-sm-block(@click="$bvModal.show('change-bot-token'), editField()")
				svg.ml-auto.d-block.d-sm-none.flex-shrink-0.bot-setup__dots(@click="onOpenPopover" :id="'bot-token'" xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none")
					g(clip-path="url(#a)")
						path(d="M1.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM9 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM16.5 10.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z")
							defs
								clipPath(id="a")
									path(fill="#fff" d="M0 0h18v18H0z")
				b-popover(v-if="isMobile" ref="popoverMenuDotsToken" :target="'bot-token'" placement="left" :custom-class="'popover-settings'" trigger="click")
					a.fontello-btn.fontello-btn-primary
						i.text-primary.fontello.fontello-icon-edit.cursor-pointer(@click="$bvModal.show('change-bot-token'), editField()")

		modal-general(v-if="!isMobile" :id="'change-bot-name'" :title="$t('bots.changeBotName')" :contentClass="'bot-setup__modal-edit'")
			change-bot(:element="'name'" :input="true" @close="$bvModal.hide('change-bot-name')")

		b-modal(v-if="isMobile" :id="'change-bot-name'" content-class="bot-setup__modal" header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('bots.changeBotName') }}
				icon-close(@click="close()")
			change-bot(:element="'name'" :input="true" @close="$bvModal.hide('change-bot-name')")

		modal-general(v-if="!isMobile" :id="'change-bot-info'" :title="$t('bots.changeBotInfo')" :contentClass="'bot-setup__modal-edit'")
			change-bot(:element="'info'" @close="$bvModal.hide('change-bot-info')")

		b-modal(v-if="isMobile" :id="'change-bot-info'" content-class="bot-setup__modal" header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('bots.changeBotInfo') }}
				icon-close(@click="close()")
			change-bot(:element="'info'" @close="$bvModal.hide('change-bot-info')")

		modal-general(v-if="!isMobile" :id="'change-bot-desc'" :title="$t('bots.changeBotDesc')" :contentClass="'bot-setup__modal-edit'")
			change-bot(:element="'desc'" @close="$bvModal.hide('change-bot-desc')")

		b-modal(v-if="isMobile" :id="'change-bot-desc'" content-class="bot-setup__modal" header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('bots.changeBotDesc') }}
				icon-close(@click="close()")
			change-bot(:element="'desc'" @close="$bvModal.hide('change-bot-desc')")

		modal-general(v-if="!isMobile" :id="'change-bot-token'" :title="$t('bots.editAPIToken')" :contentClass="'bot-setup__modal-token'")
			change-bot(:element="'token'" :label="$t('bots.settings.APIToken')" @close="$bvModal.hide('change-bot-token')")

		b-modal(v-if="isMobile" :id="'change-bot-token'" content-class="bot-setup__modal" header-class="p-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3 {{ $t('bots.editAPIToken') }}
				icon-close(@click="close()")
			change-bot(:element="'token'" :label="$t('bots.settings.APIToken')" @close="$bvModal.hide('change-bot-token')")

		modal-general(:id="'change-bot-avatar'" :title="$t('bots.changeBotAvatar.title')" :contentClass="'bot-setup__modal-change-avatar'")
			change-bot-avatar(@close="$bvModal.hide('change-bot-avatar')")
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import PwIconCopy from '@/components/Form/PwIconCopy';
import ChangeBot from '@/components/Common/ChangeBot';
import ChangeBotAvatar from '@/components/Common/ChangeBotAvatar';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import IconClose from '@/components/Common/IconClose';
import AttractionLink from '@/components/Common/AttractionLink';
import ModalTooltip from '@/components/Modals/ModalTooltip';

export default {
    name: 'BotSetup',
    components: {
        PwIconCopy,
        ChangeBot,
        ChangeBotAvatar,
        ModalGeneral,
        IconClose,
        AttractionLink,
        ModalTooltip,
    },
    data: () => ({
        isLoading: false,
    }),
    computed: {
        ...mapGetters({
            bot: 'project/bot',
            item: 'project/opened',
            lastOpenedProjectId: 'project/lastOpenedProjectId',
        }),
        tokenStart() {
            return this.bot.token.slice(0, 4);
        },
        tokenEnd() {
            return this.bot.token.slice(this.bot.token.length - 4, this.bot.token.length);
        },
        paymentLink() {
            return this.item ? `${this.paymentLinkDomain}${this.item.hashIdCustom}` : '';
        },
        paymentLinkDefault() {
            if (!this.item) return '';

            if (this.item.hashIdCustom != this.item.hashId) return this.paymentLink;

            return this.item ? `${this.paymentLinkDomain}${this.item.hashId}` : '';
        },
        paymentLinkDomain() {
            return `${window.location.protocol}//${window.location.host.replace('merchant.', '')}/`;
        },
    },
    created() {
        window.addEventListener('click', e => {
            if (this.overlay === true) {
                const target = e.target;

                if (!target.closest('.b-popover') && !target.closest('.bot-setup__dots')) {
                    this.onClosePopover();
                }
            }
        });
    },
    methods: {
        ...mapActions({
            refreshBot: 'bot/refresh',
            getProject: 'project/id',
        }),
        async refresh() {
            this.isLoading = true;

            this.refreshBot({ id: this.bot.id })
                .then(i => {
                    this.getProject({ id: this.lastOpenedProjectId });
                    this.isLoading = false;
                    this.$notify('success', this.$t('success.infoUpdated'));
                })
                .catch(v => {
                    this.getProject({ id: this.lastOpenedProjectId });
                    this.$notify('error', this.$t('errors.errorUpdate'));
                    this.isLoading = false;
                });
        },
        editField() {
            this.onClosePopover();
        },
        onOpenPopover() {
            document.body.classList.add('overlay');
            this.overlay = true;
        },
        onClosePopover() {
            if (this.$refs.popoverMenuDotsName) {
                this.$refs.popoverMenuDotsName.$emit('close');
            }

            if (this.$refs.popoverMenuDotsAbout) {
                this.$refs.popoverMenuDotsAbout.$emit('close');
            }

            if (this.$refs.popoverMenuDotsDesc) {
                this.$refs.popoverMenuDotsDesc.$emit('close');
            }

            if (this.$refs.popoverMenuDotsToken) {
                this.$refs.popoverMenuDotsToken.$emit('close');
            }

            document.body.classList.remove('overlay');
            this.overlay = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.bot-setup {
    max-width: 951px;
    padding-bottom: 37px;

    @include respond-below(xs) {
        padding-bottom: 18px;
    }

    &__header {
        margin-bottom: 24px;

        @include respond-below(xs) {
            margin-bottom: 16.5px;
            margin-top: -3px;
        }
    }

    &__back {
        @include respond-below(xs) {
            margin-right: 13px !important;
        }
    }

    &__title {
        @include respond-below(xs) {
            font-size: 18px !important;
        }

        &-question {
            margin-left: 11px;

            @include respond-below(xs) {
                margin-left: 9px;
            }
        }
    }

    &__subtitle {
        &-token {
            @include respond-below(xs) {
                font-size: 16px !important;
                margin-bottom: -5px !important;
            }
        }
    }

    &__basic {
        margin-top: 20px;
        margin-bottom: 22px;

        @include respond-below(xs) {
            margin-top: 15px;
            margin-bottom: 8px;
        }
    }

    &__logo {
        background-color: var(--primary-bg-color);
        color: var(--primary-text-color);
        text-transform: uppercase;
        font-weight: 600;
        font-size: 62px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 145px;
        height: 145px;
        border-radius: 50%;
        border: 4px solid var(--foreground-color);

        @include respond-below(xs) {
            width: 116px;
            height: 116px;
            font-size: 52px;
            margin-right: 15px;
        }

        @media (max-width: 360px) {
            width: 105px;
            height: 105px;
        }
    }

    &__name {
        word-break: break-word;
    }

    &__username {
        word-break: break-word;
        margin-right: 10px;

        @include respond-below(xs) {
            width: 100%;
            display: block;
        }
    }

    &__copy {
        @include respond-below(xs) {
            margin-left: 0;
        }
    }

    &__start-chat {
        @include respond-below(xs) {
            margin-top: 15px;
        }
    }

    .connected-bot {
        padding-top: 20px;

        @include respond-below(xs) {
            padding: 15px 17px 15px 17px;
        }

        &__right {
            width: calc(100% - 145px - 20px);

            @include respond-below(xs) {
                margin-left: 0;
                width: calc(100% - 116px - 15px);
            }

            @media (max-width: 360px) {
                width: calc(100% - 105px - 15px);
            }
        }

        &__btn {
            min-width: 170px;
            height: 36px;

            @media (max-width: 578.98px) {
                min-width: 100px;
            }

            &-start-chat {
                display: none;
            }

            @media (max-width: 578.98px) {
                &-avatar {
                    background-color: inherit;
                    padding: 0;
                    margin-right: 0;
                    justify-content: flex-start;
                    color: var(--primary-text-color);
                    height: 24px;

                    &:active {
                        color: var(--primary-text-color);
                        background: none;
                    }
                }

                &-chat {
                    display: none;
                }

                &-start-chat {
                    display: flex;
                    height: 48px;
                }
            }
        }
    }

    &__inner {
        margin-bottom: 25px;

        @include respond-below(xs) {
            margin-bottom: 19px;
        }

        &-status {
            margin-bottom: 10px;
        }
    }

    &__info {
        @include respond-below(xs) {
            width: 100%;
        }
    }

    &__about {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__desc {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__token {
        overflow-x: hidden;

        @include respond-below(xs) {
            margin-right: 10px;
        }
    }

    &__delete {
        border: 1px solid var(--brand-error);
        max-width: 238px;
        margin-bottom: 37px;
        margin-top: 18px;
        background-color: var(--foreground-color);

        @include respond-below(xs) {
            max-width: 100%;
            margin-bottom: 18px;
            margin-top: 20px;
            height: 50px;
        }

        &:hover {
            color: var(--foreground-color) !important;
            background-color: var(--error-color-hover);
            border-color: var(--error-color);
            box-shadow: none;
        }
    }
}
</style>
